<template>
  <div id="Shipping">
    <MyBanner
      :text="$t('banner.accessDcAddressBookPage')"
      v-if="$t('banner.accessDcAddressBookPage') != ''"
    />
    <MyHeaderPage
      :title="$t('accessDc.addressBook.title')"
      :subtitle="$t('accessDc.addressBook.subTitle')"
    />
    <br /><br />

    <!-- TABLE START -->
    <TabContainer>
      <template #tabs>
        <Tab
          id="tabOne"
          :label="$t('accessDc.addressBook.tab1Label')"
          @click="setTab(1)"
          :class="{ 'is-active': tab_index === 1 }"
        ></Tab>
        <Tab
          id="tabTwo"
          :label="$t('accessDc.addressBook.tab2Label')"
          @click="setTab(2)"
          :class="{ 'is-active': tab_index === 2 }"
        ></Tab>
      </template>
      <!-- Define Tab Content -->
      <template #content>
        <!-- Content for Tab 1 (Existing Content) -->
        <div
          class="mdl-tabs__panel"
          :class="{ 'is-active': tab_index === 1 }"
          v-show="tab_index === 1"
        >
          <div id="TicketsList">
            <div class="listTicket">
              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--4-col mdl-cell mdl-cell--12-col-phone"
                >
                  <div class="filterTickets">
                    <div class="contentInputFilter">
                      <div class="contentIcon">
                        <span class="material-icons">search</span>
                      </div>
                      <div class="contentInput">
                        <input
                          type="text"
                          :placeholder="$t('actions.text_search_placeholder')"
                          v-model="searchString"
                          @keyup="searchByString"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!IsMobile">
                <table v-if="columns.length > 0">
                  <thead>
                    <tr>
                      <th
                        v-for="(header, keyHead) in this.columns"
                        :key="keyHead"
                      >
                        {{ $t("accessDc.addressBook.col." + header) }}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="this.viewLoader">
                    <tr
                      v-for="(item, keyItem) in this.items"
                      :key="keyItem"
                      :class="{ odd: keyItem % 2 }"
                    >
                      <td
                        v-for="(index, keyIndex) in this.columns.length"
                        :key="keyIndex"
                      >
                        <span>{{ item[this.columns[keyIndex]] }}</span>
                      </td>
                      <td
                        style="text-align: center"
                        v-tooltip:top.tooltip="
                          $t('accessDc.addressBook.deleteItem', {
                            name: item.surname + ' ' + item.name,
                          })
                        "
                      >
                        <div
                          class="material-icons deleteItem"
                          @click="deleteItem(item.id)"
                        >
                          delete
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="IsMobile && colMobile.length > 0">
                <table>
                  <thead>
                    <tr>
                      <th
                        style="text-align: center"
                        v-for="(header, keyHead) in colMobile"
                        :key="keyHead"
                      >
                        <span>{{
                          $t("accessDc.addressBook.col." + header)
                        }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <TicketsMobileRow
                      class="marco"
                      v-for="(item, keyItem) in this.items"
                      :key="keyItem"
                    >
                      <template #first-col>{{ item.surname }}</template>
                      <template #second-col>{{ item.name }}</template>
                      <template #url-label>
                        <p style="text-align: center">{{ item.email }}</p>
                        <div style="text-align: center">
                          <div
                            class="material-icons deleteItem"
                            @click="deleteItem(item.id)"
                          >
                            delete
                          </div>
                        </div>
                      </template>
                    </TicketsMobileRow>
                  </tbody>
                </table>
              </div>
              <!-- No Items Message -->
              <div class="noTickets" v-if="items.length == 0 && viewLoader">
                <br />{{ $t("accessDc.addressBook.noUsers") }}
              </div>

              <!-- Loader and Error Components -->
              <div>
                <br /><br />
                <MyLoading :viewLoader="viewLoader" type="" />
                <MyError
                  :viewError="viewError"
                  :message="$t('tickets.error')"
                />
              </div>
              <br /><br />

              <!-- Paginator -->
              <Paginator
                v-show="total_page > 1"
                :total-page="total_page"
                :current-page="current_page"
                @change-page="changePage"
                @prev-page="beforePage"
                @next-page="nextPage"
              />
              <br /><br />
            </div>
          </div>
        </div>
        <!-- Content for Tab 2 (Cars List) -->
        <div
          class="mdl-tabs__panel"
          :class="{ 'is-active': tab_index === 2 }"
          v-show="tab_index === 2"
        >
          <!-- Cars List Content -->
          <div id="TicketsList">
            <div class="listTicket">
              <!-- Search Bar -->
              <div class="mdl-grid">
                <div
                  class="mdl-cell mdl-cell--4-col mdl-cell mdl-cell--12-col-phone"
                >
                  <div class="filterTickets">
                    <div class="contentInputFilter">
                      <div class="contentIcon">
                        <span class="material-icons">search</span>
                      </div>
                      <div class="contentInput">
                        <input
                          type="text"
                          :placeholder="$t('actions.text_search_placeholder')"
                          v-model="carsSearchString"
                          @keyup="searchCarsByString"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Table for Desktop -->
              <div v-if="!IsMobile">
                <table v-if="carColumns.length > 0">
                  <thead>
                    <tr>
                      <th
                        v-for="(header, keyHead) in carColumns"
                        :key="keyHead"
                      >
                        {{ $t("accessDc.cars.col." + header) }}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="carsViewLoader">
                    <tr
                      v-for="(item, keyItem) in carItems"
                      :key="keyItem"
                      :class="{ odd: keyItem % 2 }"
                    >
                      <td>
                        <span>{{ item.name }}</span>
                      </td>
                      <td>
                        <span>{{ item.license }}</span>
                      </td>
                      <td
                        style="text-align: center"
                        v-tooltip:top.tooltip="
                          $t('accessDc.cars.deleteItem', {
                            name: item.name,
                          })
                        "
                      >
                        <div
                          class="material-icons deleteItem"
                          @click="deleteCarItem(item.id)"
                        >
                          delete
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Table for Mobile -->
              <div v-if="IsMobile && carColMobile.length > 0">
                <table>
                  <thead>
                    <tr>
                      <th
                        style="text-align: center"
                        v-for="(header, keyHead) in carColMobile"
                        :key="keyHead"
                      >
                        <span>{{ $t("accessDc.cars.col." + header) }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <TicketsMobileRow
                      class="marco"
                      v-for="(item, keyItem) in carItems"
                      :key="keyItem"
                    >
                      <template #first-col>{{ item.make }}</template>
                      <template #second-col>{{ item.model }}</template>
                      <template #url-label>
                        <p style="text-align: center">{{ item.year }}</p>
                        <div style="text-align: center">
                          <div
                            class="material-icons deleteItem"
                            @click="deleteCarItem(item.id)"
                          >
                            delete
                          </div>
                        </div>
                      </template>
                    </TicketsMobileRow>
                  </tbody>
                </table>
              </div>

              <!-- No Items Message -->
              <div
                class="noCars"
                v-if="carItems.length === 0 && carsViewLoader"
              >
                <br />{{ $t("accessDc.cars.noCars") }}
              </div>

              <!-- Loader and Error Components -->
              <div>
                <br /><br />
                <MyLoading :viewLoader="carsViewLoader" type="" />
                <MyError
                  :viewError="carsViewError"
                  :message="$t('cars.error')"
                />
              </div>
              <br /><br />

              <!-- Paginator -->
              <Paginator
                v-show="carsTotalPage > 1"
                :total-page="carsTotalPage"
                :current-page="carsCurrentPage"
                @change-page="changeCarsPage"
                @prev-page="beforeCarsPage"
                @next-page="nextCarsPage"
              />
              <br /><br />
            </div>
          </div>
        </div>

        <!-- Loader when content is not ready -->
        <div class="mdl-grid" v-show="!viewLoader">
          <div class="mdl-cell mdl-cell--12-col" style="text-align: center">
            <MyLoading :viewLoader="viewLoader" type="" />
          </div>
        </div>
      </template>
    </TabContainer>
    <!-- TABLE END -->
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/Shipping.scss";
import { useUserStore } from "@/stores/UserStore";

import MyLoading from "@/components/MyLoading.vue";
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";
import TicketsMobileRow from "@/components/Tables/TicketsMobileRow.vue";
import "@/assets/scss/TicketsList.scss";
import TabContainer from "@/components/Utils/TabContainer.vue";
import Tab from "@/components/Utils/Tab.vue";

export default {
  name: "AccessRequestAddressBook",
  components: {
    TabContainer,
    MyBanner,
    MyHeaderPage,
    MyLoading,
    MyError,
    Tab,
    Paginator,
    TicketsMobileRow,
  },
  data() {
    return {
      tab_index: 1,
      columns: ["surname", "name", "company", "email"],
      items: [],
      searchString: "",
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": "multipart/form-data",
      },
      page_size: 10,
      total_page: 1,
      current_page: 1,
      viewLoader: true,
      viewError: false,
      messageError: "",
      IsMobile: this.$IsMobile(),
      colMobile: ["surname", "name"],

      allItems: [],
      indexFirst: 0,
      getGammaId: 0,

      // Data for Cars List (Tab 2)
      carColumns: ["name", "license"],
      carItems: [],
      carsSearchString: "",
      carsPageSize: 10,
      carsTotalPage: 1,
      carsCurrentPage: 1,
      carsViewLoader: true,
      carsViewError: false,
      carsMessageError: "",
      carColMobile: ["name", "license"],
      allCarItems: [],
      carsIndexFirst: 0,
    };
  },
  methods: {
    setTab(index) {
      this.tab_index = index;
      // Optionally, you can fetch data when the tab is switched
      if (index === 2 && this.carItems.length === 0) {
        this.getCarsList();
      }
    },
    searchByString() {
      if (this.searchString.length <= 3 || this.searchString.length == 0) {
        this.total_page = Math.ceil(this.allItems.length / this.page_size);
        this.current_page = 1;
        this.indexFirst = 0;
        this.items = this.allItems.slice(
          this.indexFirst,
          this.current_page * this.page_size
        );
      } else {
        this.total_page = 0;

        this.items = [];
        this.allItems.forEach((elm) => {
          let surname = elm.surname
            ? elm.surname
                .toLowerCase()
                .includes(this.searchString.toLowerCase())
            : false;
          let name = elm.name
            ? elm.name.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          let company = elm.company
            ? elm.company
                .toLowerCase()
                .includes(this.searchString.toLowerCase())
            : false;
          let email = elm.email
            ? elm.email.toLowerCase().includes(this.searchString.toLowerCase())
            : false;
          if (surname || name || company || email) {
            this.items.push(elm);
          }
        });
      }
    },
    getAddressBook() {
      this.viewLoader = false;

      this.total_page = 0;
      this.current_page = 1;

      const self = this;

      this.viewError = false;

      this.items = [];

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/get-users/" +
            this.getGammaId,
          { headers: this.he }
        )
        .then((response) => {
          this.viewLoader = true;

          if (response.data.status == "ok" && response.data.data.length > 0) {
            this.allItems = response.data.data;
            this.total_page = Math.ceil(
              response.data.data.length / this.page_size
            );
            this.items = this.allItems.slice(
              this.indexFirst,
              this.current_page * this.page_size
            );
            this.indexFirst = 11;
          }
        })
        .catch(function (err) {
          console.log(err);
          if (err.request.status == 404) {
            self.viewLoader = true;
            return;
          }
          self.viewLoader = true;
          self.viewError = true; //err.message; //"Nessuna copertura";
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    changePage(page) {
      this.current_page = parseInt(page);
      this.updateElms();
    },
    nextPage() {
      if (this.current_page == this.total_page) {
        return;
      }
      this.current_page = parseInt(this.current_page) + 1;
      this.updateElms();
    },
    beforePage() {
      if (this.current_page == 1) {
        return;
      }
      this.current_page = parseInt(this.current_page) - 1;
      this.updateElms();
    },
    updateElms() {
      let lastTen = this.current_page * this.page_size;
      this.indexFirst = lastTen - (this.page_size - 1);
      this.items = this.allItems.slice(
        this.indexFirst,
        this.current_page * this.page_size
      );
    },
    deleteItem(address_book_id) {
      this.axios
        .delete(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/delete-user/" +
            address_book_id,
          { headers: this.he }
        )
        .then(() => {
          this.searchString = "";
          this.getAddressBook();
        });
    },
    ctrlGammaId() {
      const userStore = useUserStore();
      if (
        (userStore.user.account && userStore.user.account.id) ||
        this.getGammaId > 0
      ) {
        this.getGammaId = userStore.user.account.id;
        this.getAddressBook();
        return;
      }
      setTimeout(() => {
        this.ctrlGammaId();
      }, 1500);
    },
    searchCarsByString() {
      if (
        this.carsSearchString.length <= 3 ||
        this.carsSearchString.length == 0
      ) {
        this.carsTotalPage = Math.ceil(
          this.allCarItems.length / this.carsPageSize
        );
        this.carsCurrentPage = 1;
        this.carsIndexFirst = 0;
        this.carItems = this.allCarItems.slice(
          this.carsIndexFirst,
          this.carsCurrentPage * this.carsPageSize
        );
      } else {
        this.carItems = this.allCarItems.filter((item) => {
          return (
            (item.name &&
              item.name
                .toLowerCase()
                .includes(this.carsSearchString.toLowerCase())) ||
            (item.license &&
              item.license
                .toLowerCase()
                .includes(this.carsSearchString.toLowerCase()))
          );
        });
      }
    },
    getCarsList() {
      this.carsViewLoader = false;

      this.carsTotalPage = 0;
      this.carsCurrentPage = 1;

      const self = this;

      this.carsViewError = false;

      this.carItems = [];

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/get-cars/" +
            this.getGammaId,
          { headers: this.he }
        )
        .then((response) => {
          this.carsViewLoader = true;

          if (response.data.status === "ok" && response.data.data.length > 0) {
            this.allCarItems = response.data.data;
            this.carsTotalPage = Math.ceil(
              response.data.data.length / this.carsPageSize
            );
            this.carItems = this.allCarItems.slice(
              this.carsIndexFirst,
              this.carsCurrentPage * this.carsPageSize
            );
            this.carsIndexFirst = this.carsPageSize + 1;
          }
        })
        .catch(function (err) {
          console.log(err);
          if (err.request.status == 404) {
            self.carsViewLoader = true;
            return;
          }
          self.carsViewLoader = true;
          self.carsViewError = true;
          self.carsMessageError = err.message;
          setTimeout(() => {
            self.carsViewError = false;
          }, 5000);
        });
    },
    deleteCarItem(car_id) {
      this.axios
        .delete(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/delete-car/" +
            car_id,
          { headers: this.he }
        )
        .then(() => {
          this.carsSearchString = "";
          this.getCarsList();
        });
    },
    changeCarsPage(page) {
      this.carsCurrentPage = parseInt(page);
      this.updateCarElms();
    },
    nextCarsPage() {
      if (this.carsCurrentPage == this.carsTotalPage) {
        return;
      }
      this.carsCurrentPage = parseInt(this.carsCurrentPage) + 1;
      this.updateCarElms();
    },
    beforeCarsPage() {
      if (this.carsCurrentPage == 1) {
        return;
      }
      this.carsCurrentPage = parseInt(this.carsCurrentPage) - 1;
      this.updateCarElms();
    },
    updateCarElms() {
      let lastItems = this.carsCurrentPage * this.carsPageSize;
      let firstIndex = lastItems - this.carsPageSize;
      this.carItems = this.allCarItems.slice(firstIndex, lastItems);
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.ctrlGammaId();
    // If the second tab is active on mount, fetch cars list
    if (this.tab_index === 2) {
      this.getCarsList();
    }
  },
};
</script>
