<template>
  <div id="OrderNetworkCoverageSummary">
    <div class="contentSummary">
      <div class="padding60">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <MyHeaderPage
              :title="
                readOnly
                  ? $t('checkCoverageSummary.titleReadOnly')
                  : $t('checkCoverageSummary.titleEdit')
              "
              :calendar="true"
            />
          </div>
        </div>
        <SummaryBox>
          <template
            #itemDescription
            v-if="cartItem.related_products && cartItem.related_products.length"
          >
            {{ cartItem?.related_products[0].group_name }}
          </template>
          <template #address
            >{{ cartItem.cross_connect_details?.datacenter_address }}
          </template>
          <template #nrc>{{ cartItem?.item_total?.nrc }}</template>
          <template #mrc>{{ cartItem?.item_total?.mrc }}</template>

          <template #min_duration>{{
            cartItem.starter_subscription_time
          }}</template>
          <template #renewal>{{ cartItem.regular_subscription_time }}</template>
          <template #release_time>{{ cartItem.activation_time }}</template>
          <template #billing_period>
            <span class="detailThree" v-html='cartItem?.billing_period ? $t("billingPeriod." + cartItem.billing_period ) : $t("billingPeriod.3" )'></span>
          </template>
        </SummaryBox>
        <!-- LINEA PRINCIPALE START -->
        <div class="mdl-grid section">
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2 v-if="!cartItem?.cross_connect_details?.master_id">
              {{ $t("crossConnects.step2Heading") }}
            </h2>
            <h2 v-else>{{ $t("nav.crossConnect") }}</h2>
          </div>
          <p
            v-if="
              (cartItem.cross_connect_details?.redundancy_ids &&
                cartItem.cross_connect_details?.redundancy_ids.length) ||
              cartItem.cross_connect_details?.master_id === undefined ||
              !cartItem.cross_connect_details?.master_id
            "
            class="editInfoSummary"
            @click="editInfo(2)"
          >
            <span id="go-step-2" class="material-icons"> edit </span>
          </p>
        </div>

        <div
          class="mdl-grid info-container"
          v-if="cartItem.cross_connect_details?.master_id"
        >
          <div class="mdl-cell mdl-cell--10-col">
            <span style="font-weight: bold"
              >{{ $t("crossConnects.isRedundancyMainLine") }}
              <a
                style="
                  font-weight: bold;
                  color: #002e5f;
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="
                  this.$router.push({
                    name: 'CartItemSummary' + this.$i18n.locale,
                    params: {
                      cart_id: cartItem.cross_connect_details?.master_id,
                    },
                    query: { is_from_redundancy: true },
                  })
                "
                >qui</a
              ></span
            >
          </div>
        </div>

        <div
          class="mdl-grid"
          style="align-content: flex-start"
          v-if="
            this.cartItem.cross_connect_details &&
            !cartItem.cross_connect_details?.master_id
          "
        >
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'A - END'"
            :room="cartItem.cross_connect_details.a_end.room"
            :rack="cartItem.cross_connect_details.a_end.rack_name"
            :device-port="cartItem.cross_connect_details.a_end.device_and_port"
            :patchPanel="
              cartItem.cross_connect_details.a_end.patch_panel_to_mmr
            "
          ></AbDevice>
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'B - END'"
            :room="cartItem.cross_connect_details.b_end.room"
            :rack="cartItem.cross_connect_details.b_end.rack_name"
            :device-port="cartItem.cross_connect_details.b_end.device_and_port"
            :patchPanel="
              cartItem.cross_connect_details.b_end.patch_panel_to_mmr
            "
            :isle="cartItem.cross_connect_details.b_end.islet"
            :wing="cartItem.cross_connect_details.b_end.wing_name"
            :building="cartItem.cross_connect_details.b_end.building_name"
            :attachment="cartItem.cross_connect_details.b_end.loa"
            :attachmentName="cartItem.cross_connect_details.b_end.loa_name"
            :is-loa-provided="
              !cartItem.cross_connect_details.b_end.is_my_rack &&
              !cartItem.cross_connect_details.b_end.loa &&
              !cartItem.cross_connect_details.b_end.building
            "
          ></AbDevice>
        </div>
        <!-- LINEA PRINCIPALE END -->

        <!-- LINEA RIDONDATA START -->
        <div class="mdl-grid section">
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>{{ $t("crossConnects.ridondanza") }}</h2>
          </div>
          <div class="mdl-cell mdl-cell--2-col">
            <p
              class="editInfo"
              v-show="!cartItem?.cross_connect_details?.master_id"
              @click="editInfo(3, true)"
            >
              <span id="go-step-3" class="material-icons"> edit </span>
            </p>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col"
            style="align-self: center; margin-top: 0"
          ></div>
        </div>
        <div
          class="mdl-grid"
          v-if="
            !cartItem?.cross_connect_details?.is_redundant &&
            !cartItem?.cross_connect_details?.master_id
          "
        >
          <div
            class="mdl-cell mdl-cell--6-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <span style="font-weight: bold">{{
              $t("crossConnects.redundancyNotEnabled")
            }}</span>
          </div>
        </div>

        <div
          class="mdl-grid"
          v-else-if="
            cartItem.cross_connect_details.is_redundant &&
            cartItem.cross_connect_details.master_id
          "
        >
          <div class="mdl-cell mdl-cell--10-col">
            <span style="font-weight: bold">{{
              $t("crossConnects.isRedundancy")
            }}</span>
          </div>
        </div>

        <div class="mdl-grid" v-else>
          <div
            v-if="redundantData"
            style="display: flex; align-content: flex-start"
          >
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :title="'A - END'"
              :room="redundantData.cross_connect_details.a_end.room"
              :rack="redundantData.cross_connect_details.a_end.rack"
              :device-port="
                redundantData.cross_connect_details.a_end.device_and_port
              "
              :patchPanel="
                redundantData.cross_connect_details.a_end.patch_panel_to_mmr
              "
              :attachment="''"
            ></AbDevice>
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :title="'B - END'"
              :room="redundantData.cross_connect_details.b_end.room"
              :rack="redundantData.cross_connect_details.b_end.rack"
              :device-port="
                redundantData.cross_connect_details.b_end.device_and_port
              "
              :patchPanel="
                redundantData.cross_connect_details.b_end.patch_panel_to_mmr
              "
              :isle="redundantData.cross_connect_details.b_end.islet"
              :wing="redundantData.cross_connect_details.b_end.wing_name"
              :building="
                redundantData.cross_connect_details.b_end.building_name
              "
              :attachment="redundantData.cross_connect_details.b_end.loa"
              :attachmentName="
                redundantData.cross_connect_details.b_end.loa_name
              "
              :is-loa-provided="
                !redundantData.cross_connect_details.b_end.is_my_rack &&
                !redundantData.cross_connect_details.b_end.loa &&
                !redundantData.cross_connect_details.b_end.building
              "
            ></AbDevice>
            <div style="position: absolute; top: 90px; left: 400px">
              <FrecciaGrigia
                class="arrow-container"
                style="top: 90px; left: 400px"
                v-if="false"
              />
            </div>
          </div>
        </div>
        <!-- LINEA RIDONDATA END -->
        <div class="mdl-grid section">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--3-col-phone"
          >
            <h2>{{ $t("crossConnects.additionalInfo") }}</h2>
          </div>
          <p
            v-if="
              (cartItem.cross_connect_details?.redundancy_ids &&
                cartItem.cross_connect_details?.redundancy_ids.length) ||
              cartItem.cross_connect_details?.master_id === undefined ||
              !cartItem.cross_connect_details?.master_id
            "
            class="editInfoSummary"
            @click="editInfo(4)"
          >
            <span id="go-step-4" class="material-icons"> edit </span>
          </p>
        </div>
        <div
          class="mdl-grid info-container"
          v-if="
            cartItem.cross_connect_details?.is_redundant &&
            cartItem.cross_connect_details?.master_id
          "
        >
          <div class="mdl-cell mdl-cell--10-col">
            <span style="font-weight: bold"
              >{{ $t("crossConnects.isRedundancyContact") }}
              <a
                style="
                  font-weight: bold;
                  color: #002e5f;
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="
                  this.$router.push({
                    name: 'CartItemSummary' + this.$i18n.locale,
                    params: {
                      cart_id: cartItem.cross_connect_details?.master_id,
                    },
                    query: { is_from_redundancy: true },
                  })
                "
                >qui</a
              ></span
            >
          </div>
        </div>
        <div class="info-container" v-else>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">{{
                $t("crossConnects.orderDetails")
              }}</span>
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <div class="info-item">
                <span class="title"
                  >{{ $t("crossConnects.purchaseOrder") }}
                  <span class="value">
                    {{ cartItem?.client_po }}
                  </span></span
                >
              </div>
            </div>
            <div class="mdl-cell mdl-cell--8-col">
              <div class="info-item">
                <span class="title"
                  >Note:
                  <span class="value" style="font-family: OpenSansSemiBold">
                    {{ cartItem?.notes }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <br />
          <div
            ref="scrollToMe"
            class="mdl-grid"
            v-if="cartItem?.cross_connect_details?.contact"
          >
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">
                {{ $t("crossConnects.crossConnectReferral") }}:</span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("crossConnects.fullName") }}:
                <span class="value" style="margin-left: 0"
                  >{{ cartItem?.cross_connect_details.contact?.first_name }}
                  {{ cartItem?.cross_connect_details.contact?.last_name }}</span
                ></span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("refTicket.phoneRef") }}:
                <span class="value" style="margin-left: 0">{{
                  cartItem?.cross_connect_details.contact?.phone
                }}</span></span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title">{{ $t("refTicket.emailRef") }}:</span>
              <span class="value" style="margin-left: 0">{{
                cartItem?.cross_connect_details.contact.email
              }}</span>
            </div>
          </div>
        </div>
        <div>
          <div style="text-align: center; margin-top: 60px">
            <button class="cdlButton" @click="goToCart()">
              {{ $t("cart.backToCart") }}
            </button>
          </div>
          <MyLoading :viewLoader="this.viewLoader" type="full" />
          <MyError
            :viewError="viewError"
            :message="$t('checkCoverageSummary.errorGeneric')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/CrossConnect.scss";

button.cdlButton {
  width: auto;
  padding: 20px 40px;
}

.section {
  margin-top: 4rem;
}
</style>
<script>
import AbDevice from "@/components/CrossConnect/ABDevice.vue";
import SummaryBox from "@/components/CrossConnect/SummaryBox.vue";
import MyLoading from "@/components/MyLoading.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyError from "@/components/MyError.vue";
import FrecciaGrigia from "@/components/OrderNetworkCoverage/svg/cross-svg/FrecciaGrigia.vue";
import "@/assets/scss/OrderNetworkCoverageSummary.scss";
import { useDatacenterStore } from "@/stores/DataCenterStore";

export default {
  name: "CartItemSummaryit",
  components: {
    MyHeaderPage,
    MyLoading,
    MyError,
    AbDevice,
    FrecciaGrigia,
    SummaryBox,
  },
  data() {
    return {
      dc: {},
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      cartItem: {},
      redundantData: null,
      viewLoader: false,
      viewError: false,
      readOnly: false,
    };
  },
  methods: {
    async editInfo(pageNr, redundancy) {
      const dataCenterStore = useDatacenterStore();
      if (redundancy) {
        dataCenterStore.redundancy = redundancy;
      }
      dataCenterStore.pageNumber = await pageNr;
      //TODO: Find a better method to tell if product is local/infra
      dataCenterStore.isFromvalidationIntra =
        this.cartItem?.description === "XCONNECT INFRA CAMPUS";
      dataCenterStore.isFromvalidation =
        this.cartItem?.description === "xConnect LOCAL";
      dataCenterStore.cartItem = this.cartItem;
      dataCenterStore.redundantData = this.redundantData;
      dataCenterStore.isCartItem = true;
      if (this.cartItem?.description === "XCONNECT INFRA CAMPUS") {
        await dataCenterStore.fetchKitsXconn(
          this.cartItem.cross_connect_details.datacenter_id,
          0
        );
        const kitProduct =
          dataCenterStore.xConInfo.find(
            (kit) =>
              kit.related_products[0].group_name ===
              this.cartItem?.related_products[0].group_name
          ) || {};
        console.log("kitProduct: ", kitProduct);
        if (kitProduct) {
          dataCenterStore.xc_article = kitProduct.title;
          dataCenterStore.kitRelatedProduct.title = kitProduct.title;
          const xConfInfo = kitProduct;
          const whatRequired = JSON.parse(xConfInfo.validation_schema);
          const kindOfKit =
            whatRequired.allOf[1].properties.cross_connect_details.properties
              .b_end.required;
          dataCenterStore.infraRequiredFields = kindOfKit;
          if (kindOfKit.length > 0) {
            dataCenterStore.kitLength = kindOfKit.length;
            dataCenterStore.kindOfkit = kindOfKit[0];
            console.log(dataCenterStore.kindOfkit);
          } else {
            dataCenterStore.kitLength = 0;
            dataCenterStore.kindOfkit = "";
            dataCenterStore.noRequired = true;
          }
          console.log(kindOfKit);
        }
      }

      await dataCenterStore.fetchRooms(
        this.cartItem.cross_connect_details.datacenter_id
      );
      if (dataCenterStore.isCartItem && this.redundantData) {
        if (this.redundantData.cross_connect_details.b_end.rack_id) {
          dataCenterStore.is_my_rack_red = true;
        }

        await dataCenterStore.fetchRacksBCartMode(
          this.redundantData.cross_connect_details.b_end.room_id
        );
        await dataCenterStore.fetchRacksACartMode(
          this.redundantData.cross_connect_details.a_end.room_id
        );
      }
      await dataCenterStore.fetchRacksA(
        this.cartItem.cross_connect_details.a_end.room_id
      );
      await dataCenterStore.fetchRacksB(
        this.cartItem.cross_connect_details.b_end.room_id
      );
      await dataCenterStore.mapCartItemToDatacenterState(
        this.cartItem,
        this.redundantData
      );
      if (this.cartItem.title === "xConnect LOCAL") {
        await this.$router.push({ name: "OrderCrossConnect" });
      } else {
        await this.$router.push({ name: "OrderCrossConnectIntra" });
      }
    },
    getItem() {
      this.viewLoader = false;
      const self = this;

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "sales/v1/cart-item-001/" +
            this.$route.params.cart_id,
          { headers: this.he }
        )
        .then((response) => {
          this.cartItem = response.data;
        })
        .then(() => {
          if (
            this.cartItem.cross_connect_details.redundancy_ids &&
            this.cartItem.cross_connect_details.redundancy_ids.length
          ) {
            this.getRedundantData(
              this.cartItem.cross_connect_details.redundancy_ids[0]
            );
          }
          this.viewLoader = true;
        })
        .then(() => {
          if (this.$route.query.is_from_redundancy) {
            // window.scrollTo(0, window.innerHeight + 600);
            const el = this.$refs.scrollToMe;
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
          }
        })
        .catch(function (err) {
          console.log(err);
          self.viewLoader = true;
          document.getElementsByTagName("body")[0].classList.remove("overflow");
          let params = {
            type: "KitNotFound",
            cart_id: self.cartItem.id,
            address: "",
          };
          self.$emit("openModal", params);
          return;
        });
    },
    getRedundantData(redundantMasterId) {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "sales/v1/cart-item-001/" +
            redundantMasterId,
          { headers: this.he }
        )
        .then((response) => {
          this.viewLoader = true;
          this.redundantData = response.data;
        })
        .catch(function (err) {
          console.log(err);
          self.viewLoader = true;
          return;
        });
    },
    getRelatedService(type, what, typePrice = null) {
      if (what == "name") {
        let quantity = "";
        if (type.quantity) {
          quantity = "(x" + type.quantity + ")";
        }
        return type.products.title + " " + quantity;
      } else {
        if (typePrice == "nrc") {
          return this.$PriceClean(type[0].price.nrc ?? 0);
        }
        return this.$PriceClean(type[0].price.mrc ?? 0);
      }
    },
    goToCart() {
      return this.$router.push({ name: "CartPage" + this.$i18n.locale });
    },
  },
  mounted() {
    if (localStorage.getItem("cdlSolution")) {
      let recoveryValue = JSON.parse(localStorage.getItem("cdlSolution"));
      this.cartItem = recoveryValue.form;
      this.viewLoader = true;
      this.readOnly = true;
      localStorage.removeItem("cdlSolution");
      return;
    } else {
      this.getItem(this.$route.params.id);
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams) => {
        this.redundantData = null;
        this.getItem(toParams);
      }
    );
  },
};
</script>
